<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"><i class="ficon" data-feather="bell"></i><span
      class="badge badge-pill badge-danger badge-up">{{ unreadNotifications }}</span></a>
  <ul ngbDropdownMenu aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">{{ unreadNotifications }} New</div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notifications">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar-message">
              <!-- <img src="../../../../../assets/images/img/bubble-speech.png" alt="avatar" width="32" height="32" /> -->
              <div class="avatar bg-light-primary p-10 m-0">
                <div class="avatar-content">
                  <i data-feather="info" class="font-medium-5"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="message.title" style="font-weight: 500"></p>
            <small class="notification-text">{{ message.message }}</small>
          </div>
        </div>
      </a>
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">Total Payment Received Today: {{ payment_received }}</h6>
      </div>
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" (click)="readAllnotifications()">Read all notifications</a>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>