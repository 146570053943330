import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelperMockService } from 'app/core/services/http-helper-mock.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHelperService } from 'app/core/services/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,private _httpHelper: HttpHelperService) {
    this.onApiDataChange = new BehaviorSubject('');
    // this.getNotificationsData();
  }

  /**
   * Get Notifications Data
   */
  // getNotificationsData(): Promise<any[]> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient.get('api/notifications-data').subscribe((response: any) => {
  //       this.apiData = response;
  //       this.onApiDataChange.next(this.apiData);
  //       resolve(this.apiData);
  //     }, reject);
  //   });
  // }

  getNotificationData(): Observable<any> {
    return this._httpHelper.request("/Notification/GetUserNotifications", {
      method: "GET",
    });
  }

  readAllNotificationData(): Observable<any> {
    return this._httpHelper.request("/Notification/MarkNoticationAsRead?MarkReadAll=true", {
      method: "POST",
    });
  }
  
}
