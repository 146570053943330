<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0" style="padding: unset;">

    <div class="col-lg-5 login-bg-div w-100 d-lg-flex" style="padding: unset !important;" >
      <div class="w-100 d-lg-flex">
          <img class="login-bg-logo" src="assets/images/img/logo-white.png"
          alt="Logo V2" />
          <div class="left-box">
            <h1 style="color: white;">Connecting like never before</h1>
            <p>
              Introducing a way to connect with a wide range of food suppliers, manufacturers, and distributors all over
              the world,
              without ever leaving your home or office.
            </p>
          </div>
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Reset password-->
    <div class="d-flex col-lg-7 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-8">
        <h2 class="card-title font-weight-bold mb-1">Reset Password 🔒</h2>
        <p class="card-text mb-2">Your new password must be different from previously used passwords</p>
        <form class="auth-reset-password-form mt-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-new">New Password</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input [type]="passwordTextType ? 'text' : 'password'" formControlName="newPassword"
                class="form-control form-control-merge" placeholder="············" aria-describedby="reset-password-new"
                tabindex="1" autofocus [ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }" (click)="togglePasswordTextType()"></i></span>
              </div>
            </div>
            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.newPassword.errors }">
              <div *ngIf="f.newPassword.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-confirm">Confirm Password</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input [type]="confPasswordTextType ? 'text' : 'password'" formControlName="confirmPassword"
                class="form-control form-control-merge" placeholder="············"
                aria-describedby="reset-password-confirm" tabindex="2"
                [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                      'icon-eye-off': confPasswordTextType,
                      'icon-eye': !confPasswordTextType
                    }" (click)="toggleConfPasswordTextType()"></i></span>
              </div>
            </div>
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.confirmPassword.errors }">
              <div *ngIf="f.confirmPassword.errors.required">Password is required</div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="3" rippleEffect>Set New Password</button>
        </form>
        <p class="text-center mt-2">
          <a routerLink="/auth/login"><i data-feather="chevron-left" class="mb-25"></i> Back to login</a>
        </p>
      </div>
    </div>
    <!-- /Reset password-->
  </div>
</div>
