// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'http://43.230.64.35:10800/',
  // mockUrl: 'https://9ac18eba-6ad4-44cc-85f0-458cae998fd8.mock.pstmn.io/'
  mockUrl: 'https://f75f4ce4-94bd-4c4f-b2c9-6dfb67a239c7.mock.pstmn.io/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
