<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0" style="padding: unset;">

    <div class="col-lg-5 login-bg-div w-100 d-lg-flex" style="padding: unset !important;" >
      <div class="w-100 d-lg-flex">
          <img class="login-bg-logo" src="assets/images/img/logo-white.png"
          alt="Logo V2" />
          <div class="left-box">
            <h1 style="color: white;">Connecting like never before</h1>
            <p>
              Introducing a way to connect with a wide range of food suppliers, manufacturers, and distributors all over
              the world,
              without ever leaving your home or office.
            </p>
          </div>
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Forgot password-->
    <div class="d-flex col-lg-7 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-8">
        <h2 class="card-title font-weight-bold mb-1">Forgot Password? 🔒</h2>
        <p class="card-text mb-2">Enter your email and we'll send you instructions to reset your password</p>
        <form class="auth-forgot-password-form mt-2" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label class="form-label" for="forgot-password-email">Email</label>
            <input class="form-control" type="text" formControlName="email" placeholder="john@example.com"
              aria-describedby="forgot-password-email" autofocus="" tabindex="1"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="2" rippleEffect>Send reset link</button>
        </form>
        <p class="text-center mt-2">
          <a routerLink="/auth/login"><i data-feather="chevron-left" class="mb-25"></i> Back to login</a>
        </p>
      </div>
    </div>
    <!-- /Forgot password-->
  </div>
</div>
